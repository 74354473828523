ptz-calendar-year {
  ul {
    text-align: center;
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 70px);
    justify-content: center;
    align-items: center;
    gap: 21px $petz-spacing-md;
    font-size: $petz-font-size-xs;

    li {
      height: $petz-spacing-xl;
      border-radius: 35px;
      display: flex;
      justify-content: center;

      &#today {
        border: $petz-border-sm solid;
      }

      &.selected {
        background: $petz-color-primary-brand;
        border-color: $petz-color-primary-brand !important;

        button {
          color: $petz-color-neutral-white;
        }
      }

      button {
        background: none;
        border: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
