import { Component, Event, Prop, State, h } from '@stencil/core';
import { PTZCalendarYearTypes } from './types/ptz-calendar-year.types';

@Component({
  tag: 'ptz-calendar-year',
  styleUrl: 'ptz-calendar-year.scss',
})
export class PTZCalendarYear {
  /** Propriedade que representa o valor padrão do ano selecionado.  */
  @Prop() defaultValue?: PTZCalendarYearTypes.Args['defaultValue'];

  /** Propriedade que representa o rótulo do cabeçalho do calendário do ano.  */
  @Prop() labelHeader: PTZCalendarYearTypes.Args['labelHeader'];

  /** Estado que armazena o bloco de anos atual.  */
  @State() currentYearBlock = new Date().getFullYear();

  /** Estado que armazena o índice da lista de anos.  */
  @State() indexYearList = 1;

  /** Estado que armazena a lista de anos.  */
  @State() yearList: number[] = [];

  /** Estado que armazena o ano selecionado.  */
  @State() selectedYear: number = null;

  /** Evento emitido ao clicar no cabeçalho do calendário do ano.  */
  @Event() clickHeader: PTZCalendarYearTypes.Args['clickHeader'];

  /** Evento emitido ao selecionar um ano na lista de anos.  */
  @Event({ eventName: 'calendarChange' }) onCalendarChange?: PTZCalendarYearTypes.Args['onCalendarChange'];

  private currentYear: number = new Date().getFullYear();
  private numberOfYearsBehind = 5;
  private numberOfYearsInFront = 10;

  componentWillLoad() {
    this.selectedYear = this.defaultValue;
    this.yearList = this.createYearsList(this.currentYearBlock);
    this.indexYearList = this.yearList[0];
  }

  private createYearsList(fullYear: number, currentYearIndex?: number) {
    let currentYear = fullYear;

    if (currentYearIndex) {
      currentYear -= currentYearIndex;
    }

    const yearsInFrontList = Array.from(
      {
        length: this.numberOfYearsInFront,
      },
      (_, i) => currentYear + i,
    );

    const yearsBehindList = Array.from(
      {
        length: this.numberOfYearsBehind,
      },
      (_, i) => currentYear - i - 1,
    );

    return [...yearsBehindList.reverse(), ...yearsInFrontList];
  }

  private prevYearsList(): void {
    const newYear = this.yearList[0] - this.numberOfYearsInFront;
    this.updateYearList(newYear);
  }

  private nextYearsList(): void {
    const newYear = this.yearList[this.yearList.length - 1] + this.numberOfYearsBehind + 1;
    this.updateYearList(newYear);
  }

  private updateYearList(newYear: number): void {
    this.currentYearBlock = newYear;
    this.yearList = this.createYearsList(newYear);
  }

  private onClickSelectYear(year: number) {
    this.selectedYear = year;
    this.onCalendarChange.emit(year);
    this.clickHeader.emit();
  }

  render() {
    return (
      <div>
        <header class="calendar-header">
          <button class="calendar-current-date" onClick={() => this.clickHeader.emit()}>
            <span>{this.labelHeader}</span> <ptz-icon variant="solid" size="xl" name="angle-down-solid" />{' '}
          </button>
          <div class="calendar-navigation">
            <button onClick={() => this.prevYearsList()}>
              <ptz-icon size="lg" variant="solid" color="neutral-darker-accent" name="angle-left-solid" />
            </button>
            <button onClick={() => this.nextYearsList()}>
              <ptz-icon size="lg" variant="solid" color="neutral-darker-accent" name="angle-right-solid" />
            </button>
          </div>
        </header>
        <ul class="year-list">
          {this.yearList.map(year => (
            <li class={this.selectedYear === year ? 'selected' : undefined} id={year === this.currentYear ? 'today' : undefined}>
              <button onClick={() => this.onClickSelectYear(year)}>{year}</button>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
